import { useState, useEffect, useCallback, useContext } from "react";
import Image from "next/image";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useAuth, SignUpButton } from "@clerk/nextjs";
import HeartIcon from "/public/assets/img/icons/heart.svg";
import HeartFillIcon from "/public/assets/img/icons/heart-fill.svg";
import { useFavorites } from "lib/hooks/useFavorites";
import { site } from "config";
import styles from "./FavoriteButton.module.scss";
// import SignUpSignInModal from "components/auth/clerk/SignUpSignInModal";
const SignUpSignInModal = dynamic(
  () => import("components/auth/clerk/SignUpSignInModal"),
  { ssr: true }
);

export default function FavoriteButton({ objectId, favoriteType, classNames }) {
  const [favorited, setFavorited] = useState(false);
  const [favoriteOperationLoading, setFavoriteOperationLoading] =
    useState(false);
  const { isSignedIn } = useAuth();

  const { userData, addOrRemoveFavorite } = useFavorites();
  const { userFavorites } = userData;
  const favoritesIds = userFavorites[favoriteType];
  // const { experiences, activities, travelGuides } = userFavorites;

  const router = useRouter();

  useEffect(() => {
    if (!objectId || !favoritesIds?.length) {
      return;
    }

    if (favoritesIds.includes(objectId)) {
      setFavorited(true);
    }
  }, [favoritesIds, favoriteType, objectId]);

  const manageUserFavorite = useCallback(async () => {
    try {
      setFavoriteOperationLoading(true);
      setFavorited((prev) => !prev);

      const result = await addOrRemoveFavorite({
        operation: favorited ? "remove" : "add",
        objectId,
        type: favoriteType,
      });
      setFavoriteOperationLoading(false);
    } catch (error) {
      console.error(error);
    }
  }, [addOrRemoveFavorite, favorited, objectId, favoriteType]);

  const handleHeartClick = async (event) => {
    event.preventDefault();

    if (!isSignedIn) {
      console.error("Must be signed in to favorite an item");
      return;
    }

    if (isSignedIn) {
      manageUserFavorite();
    }
  };

  const handleSignInClick = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (!isSignedIn) {
      return;
    }

    if (
      router?.query?.favorites?.includes(objectId) &&
      !experiences.includes(objectId)
    ) {
      manageUserFavorite();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedIn]);

  const favoriteImageElement = (
    <Image
      src={favorited && isSignedIn ? HeartFillIcon : HeartIcon}
      alt="Heart-shaped favorite button"
      width={25}
      height={25}
      className={`wn-favorite-button-icon`}
    />
  );

  return (
    <>
      {isSignedIn && (
        <button
          className={`${styles.favorite} ${
            favoriteOperationLoading ? styles.loading : ""
          } wn-favorite-button ${classNames ?? ""}`}
          onClick={(event) => handleHeartClick(event)}
        >
          {favoriteImageElement}
        </button>
      )}
      {!isSignedIn && (
        <>
          <div
            className={`${styles.favorite} ${classNames ?? ""} wn-favorite-button`}
            onClick={(event) => event.stopPropagation()}
          >
            <SignUpSignInModal
              buttonMode="custom"
              buttonText={favoriteImageElement}
            />
          </div>
        </>
      )}
    </>
  );
}
