import { graphQlClient } from "lib/graphql-client.js";
import React, { useContext } from "react";
import dynamic from "next/dynamic";
import Layout from "../components/Layout";

import MainSlider from "@/components/Slider/BlazeSlider/BlazeSlider";
// const BlazeVideoSlide = dynamic(() =>
//   import(
//     "components/Slider/BlazeSlider/BlazeSlide/BlazeVideoSlide/BlazeVideoSlide.jsx",
//     { ssr: false }
//   )
// );
const OurStory = dynamic(() => import("../components/IndexPage/OurStory"));
// import CardRow from "components/card/CardRow";
const CardRow = dynamic(() => import("components/card/CardRow"));
const ExperienceLinkCard = dynamic(
  () =>
    import(
      "components/ExperiencePresentation/ExperienceCard/ExperienceLinkCard"
    )
);
const StoryLinkCard = dynamic(() => import("components/card/StoryLinkCard"));
const DestinationLinkCard = dynamic(
  () => import("components/card/DestinationLinkCard")
);
import { API_URL } from "../config";
import { IndexPageQuery } from "../lib/graphql/queries";
import { fetchLayoutData, fetchLayoutDataServer } from "lib/api/strapi-layout";
import { processExperienceData } from "lib/api/strapi-experiences";
import { processDestinationData } from "lib/api/strapi-destinations";
import { processStoryData } from "lib/api/strapi-stories";
// import { insertCloudinaryTags } from "utils/image";
import { CurrencyContext } from "contexts/currency";
import styles from "./Home.module.scss";
import { generateBlazeSlides } from "utils/sliders";
import { fetchTravelGuides } from "lib/api/strapi-travel-guide";
import TravelGuideCard from "@/components/guide/components/TravelGuideCard";

export default function Home({
  homeData,
  articleData,
  featuredDestinations,
  featuredExperiences,
  experienceSlides,
  layoutData,
  travelGuides,
}) {
  const currency = useContext(CurrencyContext).currency;

  const destinationCards = featuredDestinations?.map((item, index) => {
    return (
      <DestinationLinkCard
        destination={item}
        key={`destination-${item.id}-${index}`}
        cardProps={{ data2ReadMore: true }}
      />
    );
  });

  const articleCards = articleData?.map((item, index) => {
    return (
      <StoryLinkCard
        story={item}
        key={`story-${item.id}-${index}`}
        titleHeight={2}
      />
    );
  });

  const experienceCards = featuredExperiences?.map((item, index) => {
    return (
      <ExperienceLinkCard
        experience={item}
        currency={currency}
        key={`experiences-${item.id}-${index}`}
      />
    );
  });

  const travelGuideCards = travelGuides?.map((item, index) => {
    return (
      <TravelGuideCard guide={item} key={`travel-guide-${item.id}-${index}`} />
    );
  });

  const SEO = homeData?.search_engine_optimization;

  const slides = generateBlazeSlides({
    slides: experienceSlides,
    type: "experience",
    ctaButton: true,
  });

  return (
    <Layout seo={SEO} layoutData={layoutData}>
      <div className={` hero-container`}>
        <MainSlider sideNav={true}>{[...slides]}</MainSlider>
      </div>
      <div className="variable-container">
        {featuredExperiences && (
          <div className={`${styles.featuredSection}`}>
            <CardRow
              cards={experienceCards}
              sectionTitle="Volunteer and Adventure Experiences"
              headingType="h1"
              sectionSlug="/experiences"
              sideScroll="none"
              sideScrollButtons={false}
              scrollIndicator={false}
            />
          </div>
        )}
        {destinationCards && (
          <div className={`${styles.featuredSection}`}>
            <CardRow
              cards={destinationCards}
              sectionTitle="Featured Destinations"
              sectionSlug="/destinations"
              sideScrollButtons={false}
              scrollIndicator={false}
            />
          </div>
        )}

        {travelGuides && (
          <div className={`${styles.featuredSection}`}>
            <CardRow
              cards={travelGuideCards}
              sectionTitle="Travel Guides"
              sectionSlug="/travel-guides"
              sideScrollButtons={false}
              scrollIndicator={false}
            />
          </div>
        )}

        {articleCards && (
          <div className={`${styles.featuredSection}`}>
            <CardRow
              cards={articleCards}
              sectionTitle="Latest Stories"
              // sectionSubTitle="Get inspired, get involved."
              sectionSlug="/stories"
              sideScrollButtons={false}
              scrollIndicator={false}
            />
          </div>
        )}

        <div className="our-story-area" style={{ paddingTop: "unset" }}>
          <OurStory home={homeData?.ourStoryHome} />
        </div>
      </div>
    </Layout>
  );
}

export async function getStaticProps() {
  const strapiApolloClient = graphQlClient();

  let indexPageResponse = await strapiApolloClient.query({
    query: IndexPageQuery,
  });

  const indexPageData = await indexPageResponse?.data?.home?.data;
  const indexPageAttributes =
    await indexPageResponse?.data?.home?.data?.attributes;
  const featuredArticlesData = await indexPageResponse?.data?.articles?.data;

  const homeData = {
    id: indexPageData?.id,
    intro: {
      ...indexPageAttributes?.intro,
    },
    ourStoryHome: {
      ...indexPageAttributes?.ourStoryHome,
      ourStorySlider: indexPageAttributes?.ourStoryHome?.ourStorySlider?.map(
        (slide) => {
          return {
            sliderImageAlt: slide?.sliderImageAlt,
            sliderImage: {
              url: slide?.sliderImage?.data?.attributes?.url,
            },
          };
        }
      ),
    },
    search_engine_optimization: {
      ...indexPageAttributes?.search_engine_optimization,
      metaMedia: {
        ...indexPageAttributes?.search_engine_optimization?.metaMedia?.data
          ?.attributes,
      },
    },
  };

  const featuredDestinations =
    indexPageAttributes?.featured_destinations?.data?.map(
      (destinationObject) => {
        return processDestinationData(destinationObject, { type: "basic" });
      }
    );

  const featuredExperiences =
    indexPageAttributes?.featured_experiences?.data?.map((experienceObject) => {
      return processExperienceData(experienceObject, { type: "basic" });
    });

  const articleData = featuredArticlesData?.map((articleObject) => {
    return processStoryData(articleObject, { type: "basic" });
  });

  const experienceSlides = indexPageAttributes?.slider_experiences?.data
    ?.map((experienceObject) => {
      const experience = experienceObject?.attributes;

      if (
        !experience ||
        typeof !experience?.metadata?.hero_slider[0] == "undefined" ||
        !experience?.metadata?.hero_slider[0]
      ) {
        return { hasSlide: false };
      }

      const slide = experience?.metadata?.hero_slider[0] ?? false;

      return {
        id: experienceObject?.id,
        hasSlide: true,
        sliderImageAlt: slide.sliderImageAlt,
        sliderHeader: slide.sliderHeader,
        sliderDescription: slide.sliderDescription,
        experienceData: {
          country:
            experience?.location[0]?.country?.data?.attributes?.countryName,
          currencies: experience?.metadata?.currencies,
        },
        slug: "/experience/" + experience.slug,
        sliderImageLarge: {
          formats: {
            large: {
              url: slide?.sliderImageLarge?.data?.attributes?.url,
            },
          },
        },
      };
    })
    .filter((slide) => slide.hasSlide === true);

  const travelGuides = await fetchTravelGuides({ limit: 8 });

  // const layoutData = await fetchLayoutData();
  const layoutData = await fetchLayoutDataServer();

  return {
    props: {
      homeData,
      articleData,
      featuredDestinations,
      featuredExperiences,
      experienceSlides,
      layoutData,
      travelGuides: travelGuides?.slice(0, 3),
    },
    revalidate: 120,
  };
}
