import { useRef, useEffect } from "react";
import BlazeSlider from "blaze-slider";

export function useBlazeSlider(config) {
  const sliderRef = useRef();
  const elRef = useRef();

  useEffect(() => {
    // if not already initialized
    if (!sliderRef.current) {
      let sliderInstance = new BlazeSlider(elRef.current, config);
      sliderRef.current = sliderInstance;
    }
  }, []);

  return { elRef, sliderRef };
}
