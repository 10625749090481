import React from "react";
/* eslint-disable @next/next/no-img-element */
import dynamic from "next/dynamic";
import { CldImage } from "next-cloudinary";
import Link from "next/link";
const Tag = dynamic(() => import("components/ui/Tag/Tag"));
const Divider = dynamic(() => import("components/typography/Divider/Divider"));

import FavoriteButton from "components/ui/buttons/FavoriteButton/FavoriteButton";

import styles from "./LinkCard.module.scss";

const LinkCard = ({
  title,
  slug,
  image,
  objectId,
  tags,
  data1,
  data2,
  overlayTopLeft,
  overlayTopRight,
  overlayBottomLeft,
  overlayBottomRight,
  fullWidthOverlays = [],
  titleHeight = 1,
  headerFontSize,
  data2ReadMore = false,
  readMore = false,
  divider = true,
  containerStyles,
  cardData = true,
  truncateTitle = false,
  loading = "lazy",
  priority = false,
  titleType = "p",
  imageWidth = "640",
  imageHeight = "360",
  favorite = false,
  favoriteType = "experiences",
  classes = [],
  sizes = `
        (min-width: 1200px) 380px,
        (min-width: 768px) 30vw, 
        (min-width: 515px) 45vw,
        (max-width: 515px) 100vw, 
        380px`,
}) => {
  const parsedTitleHeight = titleHeight * 32 + "px";

  const TitleTag = (props) => {
    return React.createElement(
      titleType,
      { className: props.className, style: props.style },
      props.children
    );
  };

  return (
    <article
      className={`${styles.linkCard} linkCard ${classes?.join(" ")}`}
      style={containerStyles}
    >
      <Link
        href={`${slug}`}
        className={`${styles.link}`}
        alt={`Link to ${title} page`}
      >
        <div className={`${styles.imageWrapper} `}>
          {image && (
            <CldImage
              src={image}
              alt={title ?? "Thumbnail"}
              width={imageWidth}
              height={imageHeight}
              sizes={sizes}
              crop="lfill,ar_1.78"
              // effects={["format:webp", "quality:80", "ar:1.78"]}
              // fill
              loading={loading}
              priority={priority}
              className={`${styles.image}`}
            />
          )}
          {!image && <div className={`${styles.noImage}`}></div>}

          {tags && (
            <span className={`${styles.tag}`}>
              {tags.map((item, index) => {
                if (!item?.length) {
                  return;
                }

                return (
                  <Tag
                    style={{ background: "var(--mid-background)" }}
                    key={index}
                    textTransform={true}
                  >
                    {item}
                  </Tag>
                );
              })}
            </span>
          )}
          {favorite && objectId && (
            <FavoriteButton objectId={objectId} favoriteType={favoriteType} />
          )}

          {overlayTopLeft && (
            <div
              className={`${styles.overlay} ${styles.overlayTopLeft} ${
                fullWidthOverlays?.includes("topLeft")
                  ? styles.fullWidthOverlay
                  : ""
              }`}
            >
              {overlayTopLeft}
            </div>
          )}

          {overlayTopRight && (
            <div
              className={`${styles.overlay} ${styles.overlayTopRight} ${
                fullWidthOverlays?.includes("topRight")
                  ? styles.fullWidthOverlay
                  : ""
              }`}
            >
              {overlayTopRight}
            </div>
          )}

          {overlayBottomLeft && (
            <div
              className={`${styles.overlay} ${styles.overlayBottomLeft} ${
                fullWidthOverlays?.includes("bottomLeft")
                  ? styles.fullWidthOverlay
                  : ""
              }`}
            >
              {overlayBottomLeft}
            </div>
          )}

          {overlayBottomRight && (
            <div
              className={`${styles.overlay} ${styles.overlayBottomRight} ${
                fullWidthOverlays?.includes("bottomLeft")
                  ? styles.fullWidthOverlay
                  : ""
              }`}
            >
              {overlayBottomRight}
            </div>
          )}
        </div>
        <div className={`${styles.cardContent}`}>
          {title && (
            <TitleTag
              style={{
                maxHeight: titleHeight ? parsedTitleHeight : "auto",
              }}
              className={`${styles.cardTitle} `}
            >
              {title}
            </TitleTag>
          )}

          {divider && <Divider />}

          <div className={`${styles.dataContainer}`}>
            {data1 && (
              <span className={`${styles.data} ${styles.data1}`}>{data1}</span>
            )}
            {data2 && (
              <span className={`${styles.data} ${styles.data2}`}>{data2}</span>
            )}
            {!data2 && data2ReadMore && !cardData !== "minimal" && (
              <div className={`${styles.data} ${styles.readMore}`}>
                <span className={`${styles.readMoreText}`}>Read more</span>
                <span>
                  <img
                    src="/assets/img/icon/awrro-black.svg"
                    alt="Arrow black icon"
                    width="30"
                    height="15"
                    loading="lazy"
                  />
                </span>
              </div>
            )}
          </div>

          {readMore && (
            <div className={`${styles.readMore} ${styles.readMoreBlock}`}>
              Read more
              <span>
                <img
                  src="/assets/img/icon/awrro-black.svg"
                  alt="Arrow black icon"
                  width="30"
                  height="15"
                  loading="lazy"
                />
              </span>
            </div>
          )}
        </div>
      </Link>
    </article>
  );
};
export default React.memo(LinkCard);
