import React, { useRef, useState } from "react";
import dynamic from "next/dynamic";
import BlazeSlider from "blaze-slider";
import { useBlazeSlider } from "lib/hooks/useBlazeSlider";
import "blaze-slider/dist/blaze.css";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
const PaginationItem = dynamic(() =>
  import("components/Slider/BlazeSlider/PaginationItem/PaginationItem")
);
// import ContainerScrollButtons from "components/ui/buttons/ContainerScrollButtons/ContainerScrollButtons";

import useWindowSize from "lib/hooks/useWindowSize";

import styles from "./BlazeSlider.module.scss";

export default function MainSlider({
  children,
  sideNav = false,
  maxWidth = "100%",
  maxHeight = "100%",
}) {
  // const [paginationItems, setPaginationItems] = useState([]);
  const [activePaginationIndex, setActivePaginationIndex] = useState(0);
  const hasText =
    children &&
    children?.some(
      (child) =>
        child.props.text || child.props.mobileText || child.props.ctaButton
    );

  const { elRef, sliderRef } = useBlazeSlider({
    all: {
      slidesToShow: 1,
      enableAutoplay: false,
      autoplayInterval: 5000,
      transitionDuration: 300,
      transitionTimingFunction: "ease",
      slideGap: "0px",
      loop: "true",
      stopAutoplayOnInteraction: true,
      enablePagination: true,
      draggable: true,
    },
  });
  const sideNavRef = useRef();

  const { width } = useWindowSize();

  const handlePaginationItemClick = (event, index) => {
    if (index === sliderRef.current.stateIndex) return;
    if (index < sliderRef.current.stateIndex) {
      sliderRef.current.prev(sliderRef.current.stateIndex - index);
    } else {
      sliderRef.current.next(index - sliderRef.current.stateIndex);
    }

    setActivePaginationIndex(index);
  };

  function handlePrevNextClick(event, direction) {
    if (direction === "prev") {
      sliderRef.current.prev();
    } else {
      sliderRef.current.next();
    }
  }

  // useEffect(() => {
  //   function generatePaginationItems() {

  // console.log(children);

  const paginationItems = children
    ? children
        .filter((child) => child?.props?.imageURL)
        .map((child, index) => {
          const props = child.props;
          const { imageURL } = props;

          return (
            <PaginationItem
              key={`${child.key}-${index}}`}
              title={props.title ?? ""}
              titleMaxLength={48}
              active={activePaginationIndex === index}
              // text={props.text}
              alt={props.alt ?? "Navigate to slide"}
              index={index}
              imageURL={props?.thumbnail ?? imageURL}
              onClick={handlePaginationItemClick}
              type={props?.videoId || props?.thumbnail ? "video" : "image"}
            />
          );
        })
    : [];

  //   return items;
  // }

  // if (sideNav && width && width > 1100) {
  //   const items = generatePaginationItems();
  //   setPaginationItems([...items]);
  // }
  // }, [activePaginationIndex, children]);

  return (
    <div
      className={`blaze-slider wn-hero-slider ${styles.slider} ${
        sideNav ? styles.withSideNav : ""
      }`}
      style={{ maxWidth: maxWidth, maxHeight: maxHeight }}
      ref={elRef}
    >
      <div className={`blaze-container ${styles.container}`}>
        <div className={`blaze-track-container ${styles.trackContainer}`}>
          <div className={`blaze-track ${styles.track}`}>
            {children && children}
          </div>

          {children?.length > 1 && (
            <>
              <button
                className={`blaze-prev ${styles.navButton} ${styles.previous} ${
                  hasText ? styles.withTextBar : ""
                }`}
                aria-label="Go to previous slide"
                onClick={(event) => handlePrevNextClick(event, "prev")}
              >
                <BsChevronLeft size={20} />
              </button>

              <button
                className={`blaze-next ${styles.navButton} ${styles.next} ${
                  hasText ? styles.withTextBar : ""
                }`}
                aria-label="Go to next slide"
                onClick={(event) => handlePrevNextClick(event, "next")}
              >
                <BsChevronRight size={20} />
              </button>
            </>
          )}
        </div>
      </div>

      {!sideNav && (
        <div className={`blaze-pagination ${styles.pagination}`}></div>
      )}

      <>
        <div
          className={`${styles.sideNav} side-nav variable-container`}
          ref={sideNavRef}
        >
          <div className={`${styles.sideNavItems}`}>
            {sideNav &&
              children &&
              paginationItems &&
              width != null &&
              width > 1100 &&
              paginationItems}
          </div>
        </div>
        {/* {paginationItems.length > 3 && (
            <div className={`${styles.scrollButtonsContainer}`}>
              <ContainerScrollButtons
                containerRef={sideNavRef}
                itemClass="blaze-pagination-item"
                axis="y"
                buttonStyle="yAxisRectangle"
              />
            </div>
          )} */}
      </>
    </div>
  );
}
