import Image from "next/image";
import LinkCard from "@/components/card/LinkCard";
import { formatGuideCountrySlug } from "utils/guides/slug";
import { truncateText } from "utils/text";
import styles from "./TravelGuideCard.module.scss";

import { BiCalendar } from "react-icons/bi";

export default function TravelGuideCard({ guide }) {
  const { slug, id } = guide;

  const location = guide?.location;
  const seo = guide?.seo;

  const metaTitle = seo?.metaTitle;
  const metaMedia = seo?.metaMedia;

  if (!metaTitle) {
    return <></>;
  }

  const imageUrl = metaMedia?.url;
  const alternativeText = metaMedia?.alternativeText;

  let countrySlug = formatGuideCountrySlug({ location });

  return (
    <LinkCard
      objectId={id}
      slug={`/travel-guides/${countrySlug}/${slug}`}
      // title={truncateText(metaTitle, 70)}
      image={imageUrl ?? null}
      titleHeight={2}
      overlayBottomLeft={<LocationInfo location={location} />}
      overlayTopRight={
        guide?.trip_data?.duration_days ? (
          <TripLength tripData={guide?.trip_data} />
        ) : null
      }
      fullWidthOverlays={["bottomLeft"]}
      favorite={true}
      favoriteType={"travelGuides"}
      classes={[styles.travelGuideLinkCard]}
    />
  );
}

function LocationInfo({ location }) {
  const { country, city } = location;

  if (!country && !city) return null;

  return (
    <div className={`${styles.locationInfo}`}>
      <span className={`${styles.locationIcon}`}>
        <Image
          src="/assets/img/logoSmallWhite.svg"
          alt="Logo icon"
          width="20"
          height="20"
          loading="lazy"
        />
      </span>
      <div className={`${styles.locationText}`}>
        {city && (
          <div className={`${!country ? styles.primary : styles.secondary}`}>
            {truncateText(city, 28)}
          </div>
        )}
        {country && <div className={`${styles.primary}`}>{country}</div>}
      </div>
    </div>
  );
}

function TripLength({ tripData }) {
  const { duration_days } = tripData;

  if (!duration_days) return null;

  return (
    <div className={`${styles.tripLength}`}>
      <span className={`${styles.tripLengthIcon}`}>
        <BiCalendar size={20} />
      </span>
      <div className={`${styles.tripLengthText}`}>
        <span className={`${styles.tripLengthDays}`}>{duration_days} days</span>
      </div>
    </div>
  );
}
