import React, { useContext } from "react";
import { UserContext } from "contexts/user";

export function useFavorites() {
  const { userData, setUserData, refreshFavorites } = useContext(UserContext);
  const { userFavorites } = userData;

  async function addOrRemoveFavorite({
    operation,
    objectId,
    objectData,
    type,
  }) {
    try {
      let convertedType = type;

      switch (type) {
        case "activities":
          convertedType = "viator";
          break;
        case "travelGuides":
          convertedType = "travel_guides";
      }

      const newFavorites =
        operation === "remove"
          ? userFavorites[type].filter((item) => item !== objectId)
          : [...userFavorites[type], objectId];

      setUserData((prev) => {
        return {
          ...prev,
          userFavorites: {
            ...prev.userFavorites,
            [type]: newFavorites,
          },
        };
      });

      const method = operation === "remove" ? "DELETE" : "POST";
      const url = `/api/user/favorites${
        method === "DELETE"
          ? `?ids=${[objectId].join(",")}&type=${convertedType}`
          : ``
      }`;

      const response = await fetch(url, {
        method: method,
        body:
          method === "POST"
            ? JSON.stringify({
                ids: [objectId],
                type: convertedType,
                objectData,
              })
            : "",
      });
      const data = await response.json();

      if (!!data.error) {
        console.error(data.error);
        return;
      }

      return data;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    userData,
    setUserData,
    addOrRemoveFavorite,
    refreshFavorites,
  };
}
