import slugify from "slugify";

export function formatGuideCountrySlug({ location }) {
  let country =
    location?.destinations?.[0]?.slug ??
    location?.destinations?.data?.[0]?.attributes?.slug ??
    location?.country ??
    "destination";

  let city = location?.city;

  if (country === "destination" && !!city) {
    // console.log("picking city: ", city);
    return slugify(city, { lower: true });
  }

  // if (country === "destination" && location?.country) {
  //   country = slugify(location?.country, { lower: true });
  //   return country;
  // }

  return slugify(country, { lower: true });
}
